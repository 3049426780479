body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.thestyle {
    background-color: 'white';
}

.fieldset {
    border: 2px groove threedface;
    padding: 1em;
}

.fieldset h2 {
    float: left;
    margin: -1.7em 0 0;
    padding: 0 .5em;
    background: #fff;
    font-size: 1em;
    font-weight: normal;
}

.displayAsterisk:after {
    content: "*\00a0";
    color: red;
    font-weight: bold;
    display: inline;
}

.labelDisplayAsterisk>.ant-form-item-label>label.ant-form-item-required::after {
    content: "*\00a0";
    color: red;
    font-weight: bold;
    display: inline;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: ""!important;
    margin-right: 0px;
}

.ant-form-item-label>label {
    display: inline;
}

.errorMessage {
    font-size: 14px;
    line-height: 1.5715;
    font-weight: bold;
    color: #ff4d4f;
}

.appLink a::before {
    top: 1 !important;
}

.ant-menu-item a::before {
    top: 100px;
}

.login-form {
    width: 450px;
    margin: 140px auto;
}

.login-form h2 {
    margin: 0 0 15px;
}

.login-logo {
    color: #ff5722;
    text-align: center;
}

.ant-form-item-label {
    white-space: normal;
    height: auto;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    padding-bottom: 10px;
}

.filesTable>div {
    margin: 0 0 0 0 !important;
}

.filesTable {
    padding-top: 5px;
}

.fileDiv,
.commentsDiv
.manageKeysDiv,
.historyDiv {
    padding: 10px 0 10px 10px !important;
    flex: 70%;
}

.ant-tree-list-holder {
    background: aliceblue;
}

.request-label {
    font-weight: normal;
}

.row-padding {
    margin-bottom: 24px;
}

.buttonStyle {
    margin: 0 0 0 8px !important;
    float: right !important;
}

.layoutStyle {
    min-height: calc(100vh - 65px) !important;
    margin-top: 65px;
}

.contentStyle {
    min-height: calc(100vh - 65px - 65px) !important;
}

.ant-select-item-option-content {
    white-space: unset;
}

.ant-select-item-option {
    white-space: pre-wrap;
}

.dividerHeading {
    font-size: 25px !important;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteAction>span {
    vertical-align: middle;
}

.deleteAction>span>a>span {
    vertical-align: middle;
}

.deleteAction>a>span {
    vertical-align: middle;
}

.formatDate {
    white-space: pre;
}

.ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid#1890FF !important;
    z-index: 2;
}

.numeric-input .ant-tooltip-inner {
    min-width: 32px;
    min-height: 37px;
}

.pg-viewer-wrapper {
    overflow-y: unset !important;
}

.photo-viewer-container {
    width: unset !important;
    height: unset !important;
}

.photo-viewer-container>img {
    width: unset !important;
    height: unset !important;
}

.react-grid-Grid {
    height: 500px !important;
}

.ant-tree-treenode {
    padding-bottom: 8px !important;
}

.fileUploadLabel {
    font-weight: bold;
    width: 100;
}

.fileDataTypesCheckbox {
    font-weight: normal;
    width: 100%;
}

.previewImage {
   min-width: 1000px;
   min-height: 600px;
   max-width: 1000px;
}

.fileIcon>svg{
    height: 20px;
    padding-bottom: 5px;
}

.information-icon > svg {
    height: 20px;
    padding-bottom: 4px;
}
.react-pdf__Page__canvas {
    margin: 0 auto;
   max-width: 100% !important;
    max-height: 100% !important;
}

.requestStatusTag {
    font-size: 14px;
    font-weight: 500;
    border-width: medium;
    white-space: break-spaces;
}

.requestStatusTagIcon {
    font-size: 1.0rem;
}
